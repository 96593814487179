import ApiService from "@/core/services/api.service";
import objectPath from "object-path";

//Machine Types
export const UPDATE_MACHINE_TYPE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`update-machine-type`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((response) => {
        reject(response);
      });
  });
};

//Machine Types
export const UPDATE_MACHINE_MODEL = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`update-machine-model`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((response) => {
        reject(response);
      });
  });
};

export const UPDATE_MACHINE_TYPES = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.patch(`machine-types`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((response) => {
        reject(response);
      });
  });
};

export const CREATE_MACHINE_MODELS = (id, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`machine-models-update/${id}`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((response) => {
        reject(response);
      });
  });
};

//Machine Types ends

export const UpdateUnit = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("unit", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const DeleteUnit = (uuid) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.delete(`unit/${uuid}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const UpdateBrand = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("brand", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const DeleteBrand = (uuid) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.delete(`brand/${uuid}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const UpdateManufacturer = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("manufacturer", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const DeleteManufacturer = (uuid) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.delete(`manufacturer/${uuid}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const validationError = ({ data }) => {
  const errors = [];
  objectPath.del(data, "debug");
  for (let row in data) {
    errors.push({
      model: true,
      message: data[row].join(", "),
    });
  }
  return errors;
};
export const GetAllProduct = () => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`listing/all`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
export const SendApprovalMail = (type, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`mail-send/${type}`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const ValidateBarcode = (url, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(url, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
export const GenrateBarcode = (type) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`barcode-generate/${type}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
export const ValidateSerialNumber = (url, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(url, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
export const GenrateSerialNumber = (type) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`generate-serial-number/${type}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
export const GetExistingData = (type, payload) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`filter/dropdown/${type}`, payload)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
export const GetAllBudget = () => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`budget/all`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
export const ValidateInputField = (url, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(url, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
